import React from 'react';
import styled from 'styled-components';

import Layout from 'components/Order/Layout';

import { md } from 'utils/breakpoints';
import TitleName from 'components/UI/Header/TitleName';

const Container = styled.div``;

const TitleBar = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;
  margin-bottom: 16px;

  @media (max-width: ${md}) {
    display: none;
  }
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  color: #000000;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px 0;
`;

const BlockTitle = styled.div`
  font-weight: 500;
`;

const UlBlock = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #000000;
  display: flex;
  position: relative;
  padding-left: 20px;

  &::before {
    content: '．';
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const PrivacyPolicy = (props) => {
  const pageTitle = '隱私權政策';

  return (
    <Container>
      <TitleName />
      <Layout {...props} pageTitle={pageTitle}>
        <Container>
          <TitleBar>{pageTitle}</TitleBar>
          <Card>
            <div>
              家福股份有限公司(以下簡稱「本公司」)及家樂福線上購物網(以下簡稱「本網站」)為提供給您更多便利及卓越的服務,以及讓您能夠安心使用本網站提供的各項服務,對於您所提供與本網站之個人資料,將依據個人資料保護法及相關法令之規範來蒐集、使用及保管,並維護您的隱私權。您可以參照以下各項聲明了解本公司的具體措施。
            </div>
            <BlockTitle>壹、客戶資料之使用目的</BlockTitle>
            <div>
              本公司會保留您在上網瀏覽或查詢時,伺服器自行產生的相關記錄,包括連線裝置或設備的IP位址、使用時間、瀏覽器、瀏覽及點選資料紀錄...等。您在本網站的個人資料,僅供本公司內部使用,我們將不會將您的資料提供給其他人士、或移作其他目的使用,除非經過您事先同意、或者本公司須與其他商業合作夥伴共用您的資料,以利提供您要求的服務,或者依據相關的法律規定。
            </div>
            <div>※本公司内部使用範圍:</div>
            <div>
              <UlBlock>
                do二人事管理(包含甄選、離職及所屬員工基本資訊、現職、學經歷、考試分發、終身學習訓練進修、考績獎懲、銓敘、薪資待遇、差勤、福利措施、褫奪公權、特殊查核或其他人事措施)
              </UlBlock>
              <UlBlock>
                當您使用本網站各項服務內容時,以本網站及相關網站之交易資料為基準。
              </UlBlock>
              <UlBlock>
                請您每次連線完畢後均確實登出以結束您的會員帳號使用。
              </UlBlock>
              <UlBlock>
                您的會員帳號、密碼及會員權益均僅供您個人使用及享有,切勿轉借、轉讓他人或與他人合用。
              </UlBlock>
              <UlBlock>
                若您的會員帳號及密碼遭盜用、不當使用或其他致使本公司及合作會員網無法辯識是否為本人親自使用之情況時,本公司及合作會員網站對此所致之損害,概不負責。
              </UlBlock>
              <UlBlock>
                本公司得依實際情形,增加、修改或終止相關服務服務。
              </UlBlock>
              <UlBlock>
                本公司得依符合法令規定前提下,不定期更新或變更相關服務資訊,相關資訊以更新後為準,本公司將以電子郵件、電話、型錄、通信網路、網路公告或其他適當方式通知消費者。
              </UlBlock>
              <UlBlock>
                本網站及本公司為提供服務之必要通知會員相關訊息時,得以其所留存之任一聯繫方式為之,您的聯繫資料如有異動應隨時以登錄本網站、電話通知客服中心等方式進行資料更新,維持資料之正確性、即時性及完整性,若因您的資料錯誤、過期或其他非可歸責本公司的原因,導致本公司送達的訊息無法接受,仍視為本公司業已完成該通知的送達,如因而產生不利益則由會員自身負擔。
              </UlBlock>
            </div>
          </Card>
        </Container>
      </Layout>
    </Container>
  );
};

export default PrivacyPolicy;
